import { createApp } from "vue";
import App from "./App.vue";
import "@/scss/style.scss";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
// applyPolyfills().then(() => {
// 	defineCustomElements(window);
// });
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import i18n from "./i18n";
import VueFeather from "vue-feather";

loadFonts();
createApp(App)
	.component(VueFeather.name, VueFeather)
	.use(i18n)
	.use(router)
	.use(store)
	.use(vuetify)
	.use(VueTelInput as any)
	.mount("#app");
