// Styles
import "@mdi/font/css/materialdesignicons.css";
import type { ThemeDefinition } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/styles";
const Lighttheme: ThemeDefinition = {
	dark: false,
	variables: {},
	colors: {
		primary: "#1e88e5",
		info: "#03c9d7",
		success: "#05b187",
		accent: "#fc4b6c",
		warning: "#fec90f",
		error: "#fc4b6c",
		secondary: "#48bbee",
	},
};

const Darktheme: ThemeDefinition = {
	dark: true,
	colors: {
		primary: "#1e88e5",
		info: "#03c9d7",
		success: "#05b187",
		accent: "#fc4b6c",
		warning: "#fec90f",
		error: "#fc4b6c",
		secondary: "#48bbee",
	},
};

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
	// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
	components,
	directives,
	theme: {
		themes: {
			light: Lighttheme,
			dark: Darktheme,
		},
	},
	defaults: {
		VBtn: {
			rounded: "md",
			flat: true,
			fontWeight: "400",
			letterSpacing: "0",
		},
		VCard: {
			flat: true,
			elevation: 0,
		},
	},
});
