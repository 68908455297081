<template>
  <v-app>
  </v-app>
</template>
<script>
export default {
  async created() { 
        return this.$router.replace({
          name: "ABC",
        });
  },
};
</script>
